import React, { useState, useEffect } from 'react'
import { Transition } from "@headlessui/react";
import { Link } from 'gatsby'
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';
import SocialmediaLinks from '../SocialmediaLinks';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useMediaQuery } from 'react-responsive'
import Container from '../Container'
import Brand from '../Brand'


const Header = ( {menuLinks, siteTitle, siteBrand, siteBrandsub } ) => {

  const [isOpen, setIsOpen] = useState(false);

  const aboveMd = useMediaQuery({ query: '(min-width: 768px)' })


 return (
	<>
  <header className="relative top-0 bg-transparent duration-300" id="top">
	  <Container>
    <div className="flex flex-row justify-between items-center mx-auto py-2">
      <div className="flex md:flex-auto items-start flex-row text-7xl lg:text-8xl text-black">
        <h1 className="uppercase font-black leading-none">

        <Brand tagline={false} color={`black`} />
        </h1>


      </div>
	  <div className={`hidden md:flex md:flex-row md:pl-1 md:mt-2 justify-start font-light items-center space-x-4 lg:space-x-6 nav-menu`}>
	  {menuLinks.map((link, index) => {
		  const linkName = link.name
		  const linkSlug = linkName.toLowerCase()

		  return (

				<AnchorLink to={link.link} key={index} partiallyActive={link.partActive} className={`${link.linkClass} tracking-wide text-black text-base lowercase  ${linkSlug}-item`}

					>
					{link.name}
				</AnchorLink>
			)

		}

		)}

	  </div>
      <div className="flex md:hidden space-x-4 lg:space-x-8 font-medium text-lg uppercase">

        <button
		  onClick={() => setIsOpen(!isOpen)}
		  type="button"
		  className="bg-transparent inline-flex items-center justify-center -mr-2 text-black hover:text-black focus:outline-none focus:ring-none"
		  aria-controls="mobile-menu"
		  aria-expanded="false"
			>
			  <span className="sr-only">Open main menu</span>
			  {!isOpen ? (
				<svg
				  className="block h-10 w-10"
				  xmlns="http://www.w3.org/2000/svg"
				  fill="none"
				  viewBox="0 0 24 24"
				  stroke="currentColor"
				  aria-hidden="true"
				>
				  <path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="0.85"
					d="M4 6h16M4 12h16M4 18h16"
				  />
				</svg>
			  ) : (
				<svg
				  className="block h-10 w-10"
				  xmlns="http://www.w3.org/2000/svg"
				  fill="none"
				  viewBox="0 0 24 24"
				  stroke="currentColor"
				  aria-hidden="true"
				>
				  <path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="0.85"
					d="M6 18L18 6M6 6l12 12"
				  />
				</svg>
			  )}
			</button>


      </div>
    </div>
    <Transition
	  show={isOpen}
	  enter="transition ease-out duration-100 transform"
	  enterFrom="opacity-0 scale-95"
	  enterTo="opacity-100 scale-100"
	  leave="transition ease-in duration-75 transform"
	  leaveFrom="opacity-100 scale-100"
	  leaveTo="opacity-0 scale-95"
	>
    <div className="md:hidden" id="mobileMenu">
		<div className="container mx-auto md:px-8 px-2">
	      <nav className="flex flex-col items-end text-right py-4 space-y-5">
	        {menuLinks.map((link, index) => {
				const linkName = link.name
				const linkSlug = linkName.toLowerCase()

				return (
	            <Link to={link.link} key={index} className={`${link.linkClass} text-black lowercase text-base ${linkSlug}-item`}>
	              {link.name}
	              </Link>
	          )})}
	      </nav>
		  </div>
    </div>

  </Transition>
</Container>
  </header>


  </>
);

}

export default Header;
