import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../../css/main.scss'


import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          brand
		      brandsub
          menuLinks {
            name
            link
      			linkClass
			      partActive
          }
        }
      }
    }
  `)

  return (
    <>
      <Header menuLinks={data.site.siteMetadata?.menuLinks} siteBrand={data.site.siteMetadata?.brand} siteBrandsub={data.site.siteMetadata?.brandsub} siteTitle={data.site.siteMetadata?.title} />
      <main className="bg-transparent pt-8 sm:pt-8 md:pt-12 duration-300">{children}</main>
      <Footer menuLinks={data.site.siteMetadata?.menuLinks} siteTitle={data.site.siteMetadata?.title} siteBrand={data.site.siteMetadata?.brand} siteBrandsub={data.site.siteMetadata?.brandsub} />
    </>
);
};

export default Layout;
