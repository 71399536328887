import React from "react"
import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SocialLinks from '../SocialLinks';
import SocialmediaLinks from '../SocialmediaLinks';
import Container from '../Container'
import Brand from '../Brand'

const Footer = ( {siteTitle, menuLinks, siteBrand, siteBrandsub}) => {



	return (
		<>

		<footer className="py-20 pb-4 bg-gradient-to-br from-gray-700 to-gray-800 duration-300 text-white">
		<Container>

			<div className="space-y-8">
				<div className="flex flex-col md:flex-row md:flex-nowrap flex-wrap md:items-center">
					<div className="flex-auto md:flex-2/5 md:order-1 order-2  space-y-5 md:space-y-6">
						<Brand tagline={true} />
					</div>
					<div className="flex-auto md:order-2 order-1 space-y-5 md:space-y-6">

					<div className="hidden md:block text-center md:text-left">
					<h6 className="text-gray-400">Learn More</h6>
					{
						menuLinks.map((link, index) => {

							return (
									<AnchorLink to={link.link} key={index} className={`text-base hover:text-gray-400 font-extralight tracking-wider text-white lowercase md:mr-5 md:ml-0 mx-4`}
								  >
								  {link.name}
								</AnchorLink>
							)

						})
					}
					</div>

					</div>
					<div className="flex-auto md:block hidden order-3 text-right">
						<AnchorLink className="inline-flex justify-center items-center w-24 h-24 hover:bg-gray-600 rounded-full bg-gray-700 text-white lowercase font-medium tracking-widest font-sans text-center leading-tight" to="/#top" stripHash>back<br/>to top</AnchorLink>
					</div>
				</div>
				<div className="flex flex-col md:flex-row md:text-left text-center justify-center items-center md:justify-between py-12 pb-8 border-t-2 border-gray-700">
					<div className="md:text-left text-center space-x-7">
						<Link className="text-sm text-gray-400 hover:text-white md:text-left text-center" to={`/privacy`}>Privacy Policy</Link>
						<Link className="text-sm text-gray-400 hover:text-white md:text-left text-center" to={`/terms`}>Terms of Use</Link>
					</div>
					<p className="mt-5 md:m-0 p-0 text-sm md:text-right text-center text-center text-gray-400">&copy; { new Date().getFullYear() } CLK Project Management. All rights reserved.</p>
				</div>
			</div>
		</Container>
		</footer>
		</>
	)
}

export default Footer
