import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'

const Brand = ( {className, tagline, color} ) => {
	const brandData = useStaticQuery(graphql`
    query BrandInfoQuery {
      site {
        siteMetadata {
          brand
		  brandsub
        }
      }
    }
  `)

	return (
		<Link className={`text-${color} font-normal leading-none inline text-7xl lg:text-8xl ${className}`} to="/">
			<span className="font-secondary">{brandData.site.siteMetadata.brand}</span>
			<div className="block text-left text-sm lg:text-md uppercase tracking-widest font-secondary">{brandData.site.siteMetadata.brandsub}</div>
			{
				tagline &&

				<p className="md:mt-5 mt-10 text-sm text-gray-400 md:max-w-sm leading-normal"
					dangerouslySetInnerHTML={{__html: "Providing constructive, insightful, and actionable business solutions to help companies expand internationally."}}
					>

				</p>
			}
		</Link>
	)
}

Brand.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	tagline: PropTypes.bool,
}

Brand.defaultProps = {
	className: null,
	tagline: true,
	color: `white`,
}

export default Brand
