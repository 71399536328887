import React from "react"
// import { Link } from 'gatsby'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { OutboundLink } from "gatsby-plugin-google-analytics"
import * as btnStyle from "../css/btn.module.scss"

const Btn = ({ linkTo, buttonTitle, isLink }) => {

	if (isLink === true) {
		return (
			<AnchorLink to={linkTo} className={`button lowercase linkButton ${btnStyle.btn} `}>{buttonTitle}</AnchorLink>
		)
	}

	return (
		<OutboundLink className={`button font-sans lowercase ${btnStyle.btn}`} href={linkTo} target="_blank" rel="noreferrer noopener">{buttonTitle}</OutboundLink>
	)

}

export default Btn
