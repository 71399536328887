import React from 'react'
import PropTypes from "prop-types"

const Container = ( { children, Slim } ) => {

	if (Slim) {
		return (
			<div className={`max-w-4xl mx-auto px-5 md:px-10 lg:px-12 xl:px-16`}>
					{children}
			</div>
		)
	}

	return (
		<div className={`max-w-full px-5 md:px-10 lg:px-12  xl:px-16`}>
				{children}
		</div>
	)
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
